<template>
  <div>
    <a-descriptions
      :column="3"
      class="iotplt-descriptions iotplt-descriptions-max-6"
    >
      <a-descriptions-item label="月份">
        {{ data.month }}
      </a-descriptions-item>

      <a-descriptions-item label="状态">
        {{ data.status }}
      </a-descriptions-item>

      <a-descriptions-item label="结算金额(元)">
        {{ data.total_fee | formatCurrency }}
      </a-descriptions-item>

      <a-descriptions-item label="佣金金额(元)">
        {{ data.total_commission_fee | formatCurrency }}
      </a-descriptions-item>
    </a-descriptions>

    <a-divider />

    <a-table
      style="overflow: auto"
      size="middle"
      :columns="tableColumns"
      :data-source="tableData"
      :pagination="false"
      :expand-icon="expandIcon"
      row-key="index"
    >
      <div
        slot="expandedRowRender"
        slot-scope="record"
      >
        <active-bill-list
          v-if="record.unit_type_slug === 'active'"
          :bill-id="agentMonthlyBillId"
        />
        <renew-bill-list
          v-else-if="record.unit_type_slug === 'renew'"
          :bill-id="agentMonthlyBillId"
        />
        <subscribe-bill-list
          v-else-if="record.unit_type_slug === 'subscribe'"
          :bill-id="agentMonthlyBillId"
        />
      </div>
    </a-table>
  </div>
</template>

<script>
import { findAgentMonthlyBill } from '@/api/agent_monthly_bill'
import { formatBigNumber, formatCurrency } from '@/utils/filter'

export default {
  name: 'BalanceStatisticAgentMonthlyBillInfo',
  components: {
    ActiveBillList: () => import('@/views/agent_monthly_bills_commission/active/top_agent_self/index'),
    RenewBillList: () => import('@/views/agent_monthly_bills_commission/renew/top_agent_self/index'),
    SubscribeBillList: () => import('@/views/agent_monthly_bills_commission/subscribe/top_agent_self/index')
  },
  data() {
    return {
      data: {},
      tableData: [],
      innerColumns: []
    }
  },
  computed: {
    agentType() {
      return this.$store.getters.userAgentType
    },

    tableColumns() {
      var columnArr = [
        {
          title: '订单类型',
          width: 350,
          dataIndex: 'unit_type'
        },
        {
          title: '个数',
          width: 250,
          customRender: formatBigNumber,
          dataIndex: 'bill_count'
        },
        {
          title: '结算金额(元)',
          customRender: formatCurrency,
          dataIndex: 'total_fee'
        },
        {
          title: '佣金金额(元)',
          customRender: formatCurrency,
          dataIndex: 'total_commission_fee'
        }
      ]

      return columnArr
    },

    agentMonthlyBillId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    expandIcon({ expanded, expandable, record, onExpand }) {
      if (!expandable || record.bill_count === 0) return null

      return (
        <a onClick={e => onExpand(record, e)}>
          {expanded ? <a-icon type='minus-square' /> : <a-icon type='plus-square' />}
        </a>
      )
    },

    fetchData() {
      findAgentMonthlyBill(this.agentMonthlyBillId).then((res) => {
        this.data = res.data
        this.$store.dispatch('SetDynamicBreadCrumbsPlaceholder', { ':month': this.data.month })
        this.tableData = [
          { index: 0, unit_type: '激活订单', unit_type_slug: 'active', bill_count: this.data.actived_count, total_fee: this.data.actived_fee, total_commission_fee: this.data.actived_commission_fee },
          { index: 1, unit_type: '续期订单', unit_type_slug: 'renew', bill_count: this.data.renewed_count, total_fee: this.data.renewed_fee, total_commission_fee: this.data.renewed_commission_fee },
          { index: 2, unit_type: '订购订单', unit_type_slug: 'subscribe', bill_count: this.data.subscribed_count, total_fee: this.data.subscribed_fee, total_commission_fee: this.data.subscribed_commission_fee }
        ]
      })
    }
  }
}
</script>

